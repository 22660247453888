<template>
  <div class="customer-service">
    <micro-app
      name="customer-service"
      :url="url"
      :data="microAppData"
      @created="handleCreate"
      @beforemount="handleBeforeMount"
      @mounted="handleMount"
      @unmount="handleUnmount"
      @error="handleError"
      @datachange="handleDataChange"
    ></micro-app>
  </div>
</template>

<script>
import config from "@/config/micro.config.js";

export default {
  name: "customer-service",
  data() {
    return {
      url: `${config.customerService}/child/customer-service/`,
      microAppData: {
        deptLevel: this.$store.state.deptLevel,
      },
    };
  },
  methods: {
    handleCreate() {
      // console.log('child-vue2 创建了')
    },

    handleBeforeMount() {
      // console.log('child-vue2 即将被渲染')
    },

    handleMount() {
      // console.log("child-supply-chain 已经渲染完成");
      // setTimeout(() => {
      //   this.microAppData = { msg: "来自基座的新数据" };
      // }, 2000);
    },

    handleUnmount() {
      console.log("customerService 卸载了");
    },

    handleError() {
      console.log("customerService 加载出错了");
    },

    handleDataChange(e) {
      console.log("来自子应用 customerService 的数据:", e.detail.data);
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-service {
}
</style>
